// @flow strict
import React from 'react';
import ProjectLayout from '../components/ProjectLayout/ProjectLayout';
import PrieVilnelesContent from '../components/ProjectsContent/PrieVilnelesContent/PrieVilnelesContent';

const PrieVilnelesPage = ({location: {pathname}}) => {
  const description = () => (
    <div>
      Logotype and a website for new apartments in Markučiai, near the Vilnelė river and the Old town. The development
      accented a home for people who value time with their family, meaningful leisure time quality access to
      entertainment.
      <br/><br/>
      Made with a team in ALL CAPS agency.
    </div>
  );
  return (
    <ProjectLayout pathname={pathname} description={description()}>
      <PrieVilnelesContent/>
    </ProjectLayout>
  );
};

export default PrieVilnelesPage;
