import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styles from './PrieVilnelesContent.module.scss';
import Image from '../../Image/Image';

const PrieVilnelesContent = () => {
  const data = useStaticQuery(graphql`query PrieVilnelesImage {
            allCloudinaryMedia(filter: {public_id: {regex: "/gallery/prie-vilneles/"}}) {
              edges {
                node {
                  secure_url
                }
              }
            }
        }`);

  const url = data.allCloudinaryMedia.edges.map(item => item.node.secure_url)[0];

  return (
    <div className={styles.prieVilnelesContent}>
      <Image url={url}/>
    </div>
  );
};

export default PrieVilnelesContent;